/* *{
    box-sizing: border-box;
  } */
  
  .game {
    max-width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* overflow: hidden; */
  }
  
  .alert {
    background-color: red;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    color: #ffffff;
  }
  .gamebox{
    margin-bottom: 30px;
  }
  .gamebox-6-7{
    margin-bottom: 30px;
  }
  .gamebox-8-11 {
    margin-bottom: 30px;
  }
  .container {
    position: "absolute";
    top : 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: "#29313d";
  }

  @media (max-width: 738px) {
    .gamebox{
      flex-basis: 10%;
    }
    .gamebox-6-7{
      flex-basis: 30%;  
    }
    .gamebox-8-11 {
      flex-basis: 30%;
    }
  }
  @media (min-width: 738px) {
    .gamebox{
      flex-basis: 30%;    
    }
    .gamebox-6-7{
      flex-basis: 30%;  
    }
    .gamebox-8-11 {
      flex-basis: 30%;
    }
  }
  @media (max-width: 576px) {
    /* CSS for mobile devices */
    .gamebox{
      flex-basis: 30%;    
    }
    .gamebox-6-7{
      flex-basis: 30%;
      width:90%;   
    }
    .gamebox-8-11 {
      flex-basis: 30%;
      width:90%; 
    }
  }
  