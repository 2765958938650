.title {
    font-family: "nyt-karnakcondensed";
    font-weight: 700;
    font-size: 37px;
    line-height: 100%;
    letter-spacing: 0.01em;
    text-align: center;
    pointer-events: none;
    padding: 0px 16px;
    border-bottom: 1px solid #d3d6da;
  }
  
  header {
    width: 100%;
    /* flex-basis: 20%; */
  }
  
  .gameInstructions {
    width: 30%;
    background-color: #ffffff;
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    border-radius: 8px;
    border: 1px solid gray;
    color: black;
    max-height: 90%;
    overflow-y: auto;
    padding: 16px;
    box-sizing: border-box;
    top: 0;
  }
  .instructions > h4 {
    margin-bottom: 3px;
  }

  .instructions > p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .container {
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .row > div {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 2px solid #878a8c;
    color: #000000;
  }

  .wordInst {
    display: flex;
    width: 60%;
    justify-content: space-between;
  }
  .instTile {
    flex: 1 1;
    display: flex;
    width: 30px;
    height: 30px;
    font-size: 12px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #000000;
    text-transform: uppercase;
    border: 2px solid #B15EFF;
    margin: 2px;
}
  
  @media (max-width: 738px) {
    .gameInstructions{
      width: 50%;
    }
  
    .gamebox{
      flex-basis: 40%;
    }
  
    .keyboard {
      flex-basis: 40% !important
    }
    .wordInst {
      display: flex;
      width: 80%;
      justify-content: space-between;
    }
    .tile {
      flex: 1 1;
      display: flex;
      width: 40px;
      height: 40px;
      font-size: 18px;
      border-radius: 2px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      color: #000000;
      text-transform: uppercase;
      border: 2px solid #B15EFF;
      margin: 2px;
  }
  }
  @media (max-width: 576px) {
    .gameInstructions{
      width: 70%;
    }
  }
  @media (max-width: 375px) {
    .gameInstructions{
      width: 80%;
    }
  }