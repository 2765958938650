.stats-container {
    display: flex;
    justify-content: space-around;
}
.ul-stats {
    display: flex;
    /* width: 100%; */
    margin-bottom: 10px;
    list-style: none;
}
.ul-stats li {
    margin: 0px 15px;
    max-width: 40px;
}
.listNum {
    font-size: 34px;
    font-weight: 500;
}
.listText {
    font-size: 12px;
    max-width: 40px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 14px;
    letter-spacing: .1em;
}

@media (max-width: 375px) {
    .ul-stats {
        display: flex;
        /* width: 80%; */
        margin-bottom: 10px;
        padding-left: 10px;
        list-style: none;
    }
    .listNum {
        font-size: 24px;
        font-weight: 500;
    }
    .listText {
        font-size: 12px;
        max-width: 30px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: .1em;
    }
}