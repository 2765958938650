
  .google-button {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 0 0 5px;
    background-color: #FFF; /* White text */
    color: #4285F4; /* Google Blue */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 95%;
    text-transform: none;
  }
  .google-logo-box {
    background-color: #ffffff; /* White box */
    padding: 6px 4px 4px 4px; /* Adjust padding as needed */
    border-radius: 4px; /* Add border-radius for rounded corners */
    margin-right: 10px; /* Add margin for spacing */
  }
  
  .google-logo {
    width: 20px;
    height: 20px;
  }
  
  .horizontal-bar {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;  
    margin-top: 5px;
    width: 95%;
  }
  
  .bar {
    flex-grow: 1;
    height: 1px;
    background-color: #B15EFF;
  }
  
  .or-text {
    margin: 0 10px;
    color: #B15EFF;
  }
  .error-container {
    background-color: #f90c0c; /* White box */
    padding: 6px 4px; /* Adjust padding as needed */
    border-radius: 4px; /* Add border-radius for rounded corners */
    margin-bottom: 5px;
    width:94%;
    color:white;
  }
  .message-container {
    background-color: rgb(19, 206, 19); /* White box */
    padding: 6px 4px; /* Adjust padding as needed */
    border-radius: 4px; /* Add border-radius for rounded corners */
    margin-bottom: 5px;
    width:94%;
    color:black;
  }
  .smallFont {
    font-size: small;
  }

  .form-container {
    display: flex;
    min-height: 100vh;
    align-items:center;
    justify-content: center;
    max-width: 400px; /* Set your desired max-width */
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
  .login-form {
    width: 100%; /* Full width */
    padding: 20px;
    border: 3px solid #a41276;
    border-radius: 10px;
    margin: auto; /* Center the form horizontally */
    background-color: #FFFB73;
    color: #5d015a;
    } 
  .profile-form {
    width: 100%; /* Full width */
    padding: 20px;
    border: 3px solid #a41276;
    border-radius: 10px;
    margin: auto; /* Center the form horizontally */
    background-color: #FFFB73;
    color: #5d015a;
    } 
    .profile-form h3 {
      padding-left: 5px;
    }
  
  
  .login-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .login-form input {
    width: 90%;
    padding: 8px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .login-form label {
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: bold;
  }
  .profile-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .profile-form input {
    width: 90%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .profile-form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  .profile-form select {
    width: 95%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  .inlineContainer {
    display: flex;
    flex-direction: row;
  }
  .inlineContainer input {
    width: 150px;
    margin-right: 5px;
  }
  .login-form h3 {
    margin: 5px;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-container img{
    width: 50px;
    border-radius: 50%;
  }
  .logoForm {
    border-radius: 50%; /* Make the logo circular */
  }
  
  .form-button {
    width: 95%;
    background-color: #a41276;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .form-button:hover {
    background-color: #3D30A2;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
  }
  
  .rounded-btn {
    width: 150px; /* Set a fixed width for the buttons */
    padding: 10px; /* Add padding for better appearance */
    border: none;
    border-radius: 20px; /* Make buttons rounded */
    margin: 5px; /* Add some margin between buttons */
    cursor: pointer;
  }
  
  
  /* Style your buttons as needed */
  .signup-btn {
    margin-right: 7px;
    background-color: #3D30A2; /* Google Blue */
    color: #ffffff; /* White text */
    /* Add your button styles here */
  }
  .signup-btn:hover {
    background-color: #020273; /* Google Blue */
  }
  
  @media (min-width: 768px) {
    .button-container {
      flex-direction: row;
    }
  }
  @media (min-width: 577px) and (max-width: 768px) {
    
  }
  @media (min-width: 479px) and (max-width: 576px) {
    .form-container {
        padding-right: 5px;
        padding-left: 5px;
        margin-right: auto;
        margin-left: auto;
    }
  }
  @media (min-width: 376px) and (max-width: 478px) {
    .form-container {
        padding-right: 5px;
        padding-left: 5px;
        margin-right: auto;
        margin-left: auto;
    }
  }
  @media (max-width: 375px) {
    .form-container {
        padding-right: 5px;
        padding-left: 5px;
        margin-right: auto;
        margin-left: auto;
    }
    .form-button {
      width: 98%;
    }
  }

  @media (max-width: 478px) {
    .inlineContainer {
      display: block;
      flex-direction: row;
    }
    .inlineContainer input {
      width: 90%;
      padding: 8px;
      margin-bottom: 5px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
