.gamesummary {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffccdd;
  background-size : cover;
  justify-content: flex-start;
  height: 91vh;
}
.gamesummary h2, h3 {
  margin : 0;
  padding: 1rem;
  text-align: center;
  font-size: 1.2rem;
  color: #140270;
  font-family: cursive, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
  .icon-fa-won {
    color: #f68606;
    font-size: 1.5rem;
  }
 
  .icon-fa-lost {
    color: #220145;
    font-size: 1.5rem;
  } 
  .wordContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-left: 5px;  }
  .wordDetail {
    padding: 10px;
  }
  .summLogo {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    margin-left: 150px;
  }
  
  @media (min-width: 376px) and (max-width: 576px) {
    .wordDetail {
      font-size: 14px;
    }
  }
  @media (max-width: 375px) {
    .gamesummary {
      height: 95vh;
    }
    .wordDetail {
      font-size: 12px;
    }
    .gamesummary h2 {
      text-align: left;
    }
  }
  