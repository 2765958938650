

.key-row {
    display: flex;
    width: 100%;
    margin: 0 auto 8px;
    touch-action: manipulation;
    justify-content: space-between;
  }
  
  .keyboard1{
    flex-basis: 30%;
    margin-bottom: 5%;
  }
  .key-row sup {
    color:rgb(239, 239, 243);
    font-size: 10px;
    position: relative;
    vertical-align:top;
    top: -0.5em;
    left:0.2em;
  }
  
  .key-row button {
    background-color: #c5becc;
  }
  
  
  @media (max-width: 738px) {
    
    .keyboard1{
      width: 95%;
      margin-bottom: 5%;
    }
    
    .key-row button {
      font-family: inherit;
      font-weight: bold;
      border: 0;
      padding: 0;
      margin: 0 6px 0 0;
      height: 50px;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      color: #000000;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
    }
  }
  
  @media (min-width: 738px){
    .key-row button {
      font-family: inherit;
      font-weight: bold;
      border: 0;
      padding: 0;
      margin: 0 6px 0 0;
      height: 50px;
      min-width: 43px;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      color: #000000;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
    }
  }